import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from './ComplementFragment.module.scss'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import ComplementForm, { IComplementGroupFormValue } from '../../componentes/complement/complementForm/ComplementForm';
import { Drawer } from '@mui/material';
import CategoryList from '../../componentes/categoryAndProducts/categoryList/CategoryList';
import ContainerDrawer from '../../componentes/ui/ContainerDrawer';
import ComplementCreateActionForm from '../../componentes/complement/complementCreateActionForm/ComplementCreateActionForm';
import ProductAsComplement, { IExistCategoryAndProducts } from '../../componentes/complement/_productAsComplement/ProductAsComplement';
import { ICategory, IProduct } from '../catalogFragment/CatalogFragment';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import CatalogService from 'services/api/catalog/CatalogService';
import GetCatalogProductCategoryListUseCase from 'modules/catalog/application/useCases/GetCatalogProductCategoryListUseCase';
import GetProductListByCategory from 'modules/catalog/application/useCases/GetProductListByCategoryUseCase';
import { v4 } from 'uuid';
import { CompositionProductType } from 'modules/catalog/domain/enums/CatalogProductEnums';
import Row from '../../componentes/ui/Row';
import ExistComplementForm from '../../componentes/complement/existComplement/ExistComplementForm';
import GetProductUseCase from 'modules/catalog/application/useCases/GetProductUseCase';
import { ICategoryItem } from '../../componentes/categoryAndProducts/categoryList/ICategoryItem';
import GetAllProductsUseCase from 'modules/catalog/application/useCases/GetAllProductsUseCase';
import { AddCircleRounded } from '@material-ui/icons';
import Button from 'components/ui/Button/Button';
import { ILocaisSelect } from '../../componentes/productForm/IProducFormValuet';
import { IProductItemToSelect } from '../../componentes/catalogListForm/selectProduct/SelectProductPicker';
export interface IComplementFragmentProps {
    //propertys
    values: IComplementGroupFormValue[];
    sharedLocals: ILocaisSelect[] | undefined
    onChange: (values: IComplementGroupFormValue[]) => void
    disabled?: boolean
    disabledComplementGroup?: boolean
    isShared: boolean | undefined
}

const catalogService = CatalogService()
const ComplementFragment: FC<IComplementFragmentProps> = ({ values, onChange, disabled, disabledComplementGroup, sharedLocals, isShared }) => {

    const [openDrawer, setOpenDrawer] = useState(false);
    const [openNewDrawer, setOpenNewDrawer] = useState(false);
    const [existComplementDrawer, setExistComplementDrawer] = useState(false);
    const [productAsComplementDrawer, setProductAsComplementDrawer] = useState(false);
    const [hasAdditional, sethasAdditional] = useState(0);
    const [categories, setCategories] = useState<ICategory[]>([]);

    const [productList, setProductList] = useState<IProductItemToSelect[]>([]);


    const [selectGroupToEdit, setSelectGroupToEdit] = useState<IComplementGroupFormValue>();
    const [isLoadingCategory, setIsLoadingCategory] = useState(false)
    const { currentLocal } = useLocal();
    const categoryIds = useRef<IExistCategoryAndProducts>({} as IExistCategoryAndProducts)

    const changeHasAdditionalHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        sethasAdditional(Number(ev.target.value));
    }, []);

    const onCloseHandle = useCallback(() => {
        setOpenDrawer(false);
        setOpenNewDrawer(false);
        setProductAsComplementDrawer(false);
        setExistComplementDrawer(false);
        setSelectGroupToEdit(undefined);
        setProductList([])
    }, [])

    const onSubmitComplementForm = useCallback((complementValues: IComplementGroupFormValue) => {
        onChange(([...values, complementValues]));
        onCloseHandle();
    }, [onChange, onCloseHandle, values])

    const onSubmitEditComplementForm = useCallback((complementValues: IComplementGroupFormValue) => {
        onChange([...values.map(item => item.complementCategory.id === complementValues.complementCategory.id ? complementValues : item)])
        onCloseHandle();
    }, [onChange, onCloseHandle, values])

    const onClickRemoveCategoryHandle = useCallback(async (category: ICategoryItem) => {
        onChange(values.filter(item => item.complementCategory.id !== category.id
        ))
    }, [onChange, values])

    const categoryList = useMemo(() => <CategoryList
        isDragInDropDisabled
        onClickRemoveCategory={onClickRemoveCategoryHandle}
        onClickEditCategory={(item) => setSelectGroupToEdit(values.find(value => value.complementCategory.id === item.id))}
        categoryList={values.map(item => ({ ...item.complementCategory, productList: item.complementProducts.map(product => ({ ...product, id: product.productId ?? product.id })) }))}
    />, [onClickRemoveCategoryHandle, values])

    const onClickNewHandle = useCallback(() => {
        setOpenNewDrawer(true);
    }, []);
    const onClickExistHandle = useCallback(() => {
        setExistComplementDrawer(true);
    }, []);

    const setProductAsComplemetDrawer = useCallback(() => {
        setProductAsComplementDrawer(true);
    }, []);

    const getCategories = useCallback(async () => {
        if (currentLocal) {
            try {
                setIsLoadingCategory(true)
                const response = await GetCatalogProductCategoryListUseCase(catalogService, currentLocal.id, sharedLocals?.map(item => item.id));             
                setCategories(response);
            } finally {
                setIsLoadingCategory(false)

            }
        }
    }, [currentLocal, sharedLocals]);

    const getProductsByCategory = useCallback(async (categoryId: string, isShared?: boolean, sharedLocals?: ILocaisSelect[]) => {
        if (currentLocal && categoryIds.current.category.id) {
            let newCategories: string[] = [];
            for (let i = 0; i < categories.length; i++) {
                const element = categories[i];
                if (element.id === categoryIds.current.category.id) {
                    if (element.locals) {
                        if (Array.isArray(element.locals)) {
                            // Filtrando valores undefined
                            newCategories = element.locals.map(local => local.id).filter((id): id is string => id !== undefined);
                        } 
                    }
                    break; 
                }
            }    
            const response = await GetProductListByCategory(
                catalogService,
                currentLocal.id,
                categoryId,
                isShared,
                sharedLocals?.map(item => item.id),
                newCategories
            );
            return response.map(item => ({
                ...item,
                disabled: values.some(value => value.complementProducts.some(product => product.productId === item.id))
            }));
        } else {
            throw new Error("Local não encontrado ou categoria não selecionada");
        }
    }, [categories, currentLocal, values]);
    
    


    const getAllProducts = useCallback(async () => {
        if (currentLocal) {
            const response = await GetAllProductsUseCase(catalogService, currentLocal.id);
            return response;
        } else {
            throw new Error("Local não encontrado");
        }
    }, [currentLocal]);

    const getProductDetail = useCallback(async (productId: string) => {
        if (currentLocal) {
            const response = await GetProductUseCase(catalogService, currentLocal.id, productId);
            return response;
        } else {
            throw new Error("Local não encontrado");
        }
    }, [currentLocal]);

    useEffect(() => {
        if (productAsComplementDrawer || existComplementDrawer) {
            getCategories()
        }

    }, [existComplementDrawer, getCategories, productAsComplementDrawer]);

    const onSubmitExistComplement = useCallback((_values: IComplementGroupFormValue) => {
        onChange([...values, { ..._values, isEdit: true }]);
        setSelectGroupToEdit({ ..._values, isEdit: true });
    }, [onChange, values])

    const onSubmitProductAsComplement = useCallback((category: ICategory, products: IProduct[]) => {
        
        if (values.some(item => item.complementCategory.categoryId === category.id)) {       
            const newValueToUpdate: IComplementGroupFormValue[] = values.map(item => {
                if (item.complementCategory.categoryId === category.id) {
                    const productsToAdd = products.filter(product => {
                        return (!item.complementProducts.find(item => item.productId === product.id))
                    })

                    return {
                        ...item,
                        id: v4(),
                        isProduct: true,
                        complementProducts: [...item.complementProducts, ...productsToAdd.map(product => ({
                            //Complement
                            id: v4(),
                            value: product.value,
                            type: CompositionProductType.VARIAVEL,
                            quantity: 1,
                            cest: product.cest,
                            cfop: product.cfop,
                            ncm: product.ncm,
                            min: 0,
                            max: 5,
                            //product 
                            name: product.name,
                            productId: product.id,
                            imageUrl: product.imageUrl,
                        }
                        ))]
                    }
                } else {
                    return { ...item, id: v4() };
                }
            })
            onChange([...newValueToUpdate]);
            setSelectGroupToEdit(newValueToUpdate.find(item => item.complementCategory.categoryId === category.id));
            return;
        }

        const _newValues: IComplementGroupFormValue = {
            isProduct: true,
            id: v4(),
            complementCategory: {
                id: v4(),
                categoryId: category.id, //Edit ProductCategory
                description: category.description,
                min: 0,
                max: 1,
                freeQuantity: 0,
                locals: categoryIds.current.category.locals
                
            },
            complementProducts: products.map((item, key) => ({
                //Complement
                id: v4(),
                value: item.value,
                type: CompositionProductType.VARIAVEL,
                quantity: 1,
                cest: item.cest,
                cfop: item.cfop,
                ncm: item.ncm,
                min: 0,
                max: 5,
                //product 
                sharedLocals: item.sharedLocals,
                name: item.name,
                productId: item.id,
                imageUrl: item.imageUrl,
            }))
        };
        onChange([...values, _newValues]);
        setSelectGroupToEdit(_newValues);

    }, [categories, onChange, values])

    useEffect(() => {
        if (values.length > 0) {
            sethasAdditional(1);
        }
    }, [values.length])

    return (
        <div id={styles.ComplementFragment} >
            <RadioGroup
                row
                value={hasAdditional}
                onChange={changeHasAdditionalHandle}
                style={!!hasAdditional ? { marginBottom: "10px" } : {}}
            >
                <FormControlLabel disabled={disabled} value={0} control={<Radio />} label={<span><b>Não</b> possui complementos</span>} />
                <FormControlLabel disabled={disabled} value={1} control={<Radio />} label={<span><b>Possui</b> complementos</span>} />
            </RadioGroup>
            {!!hasAdditional && <Row style={{ width: "100%" }}>
                {!disabledComplementGroup && 
                    <Button disabled={disabled} className={styles.addButton} color={"secondary"} variant='contained' onClick={() => setOpenDrawer(true)} endIcon={<AddCircleRounded />}>
                        Adicionar complementos
                    </Button>
                }
                <Button disabled={disabled} className={styles.addButton} color={"secondary"} variant='outlined' onClick={setProductAsComplemetDrawer}>Usar produtos existentes</Button>
            </Row>}
            {categoryList}
            <Drawer open={!!openDrawer} anchor='right' onClose={onCloseHandle}>
                <ContainerDrawer onClose={onCloseHandle} title={<div>Grupo de <b>complementos</b></div>}>
                    <ComplementCreateActionForm
                        onClickNewGroup={onClickNewHandle}
                        onClickExistGroup={onClickExistHandle}
                        defaultValues={selectGroupToEdit}
                        sharedLocals={sharedLocals}
                        openNewDrawer={openNewDrawer}
                        onCloseHandle={onCloseHandle}
                        onSubmitComplementForm={onSubmitComplementForm}
                    />
                </ContainerDrawer>
            </Drawer>
            <Drawer open={!!selectGroupToEdit} anchor='right' onClose={onCloseHandle}>
                <ContainerDrawer onClose={onCloseHandle} title={<div>Grupo de <b>complementos</b></div>}>
                    <ComplementForm
                        sharedLocals={sharedLocals}
                        disabledEditCategoryAndProduct={selectGroupToEdit?.isProduct}
                        defaultValues={selectGroupToEdit}
                        onClose={onCloseHandle}
                        onSubmit={onSubmitEditComplementForm}
                    />
                </ContainerDrawer>
            </Drawer>

            <Drawer open={!!productAsComplementDrawer} anchor='right' onClose={onCloseHandle}>
                <ContainerDrawer onClose={onCloseHandle} title={<div>Produto <b>existente</b></div>}>
                    <ProductAsComplement
                        isLoadingCategory={isLoadingCategory}
                        categories={categories}
                        getProducts={getProductsByCategory}
                        onClose={onCloseHandle}
                        sharedLocals={sharedLocals}          
                        isShared={isShared} 
                        onSubmit={onSubmitProductAsComplement}
                        categoryIds={categoryIds}
                        setProductList={setProductList}
                        productList={productList}
                    />
                </ContainerDrawer>
            </Drawer>
            <Drawer open={!!existComplementDrawer} anchor='right' onClose={onCloseHandle}>
                <ContainerDrawer onClose={onCloseHandle} title={<div>Grupo <b>existente</b></div>}>
                    <ExistComplementForm
                        // categories={categories}
                        // getProducts={getAllProducts}
                        onClose={onCloseHandle}
                        getAllProducts={getAllProducts}
                        onSubmit={onSubmitExistComplement}
                        getProductDetail={getProductDetail}
                    />
                </ContainerDrawer>
            </Drawer>

        </div>
    )
}
export default ComplementFragment