import { EnumProductType } from 'modules/products/presentation/interfaces/IProductListData';
import { ICategorySelect, IEventSelect, IPrinterSelect, IProductFormValue, IStoreSelect } from '../productForm/IProducFormValuet'
import styles from '../productForm/ProductForm.module.scss'
import { ContainerLabel } from '../ui/ContainerLabel';
import { MenuItem } from '@material-ui/core';
import { Autocomplete, IconButton } from '@mui/material';
import { InputLabel } from '../ui/InputLabel';
import { EnumGender } from 'modules/crm/presentation/intefaces/GenderEnum';
import { SwitchLabel } from '../ui/SwitchLabel';
import { ChangeEvent } from 'react';
import { SystemIdentifierEnum } from 'modules/gestaoVendas/domain/enum/GetSegmentosEnum';
import { OpenInNewOutlined } from '@mui/icons-material';

interface IProductError {
    category?: string,
    catalog?: string,
    name?: string,
    value?: string,
    observations?: string,
    complements?: string,
    sharedLocals?: {
        [index: number]: {
            category?: string;
        };
    };
}

interface IDetailsProduct {
    values: IProductFormValue
    stores: IStoreSelect[]
    printers: IPrinterSelect[]
    events?: IEventSelect[]
    categories?: ICategorySelect[]
    onChangeStore: (ev: any, complements: IStoreSelect | null) => void
    changeHandle: (ev: ChangeEvent<HTMLInputElement>) => void
    onChangePrinter: (ev: any, value: IPrinterSelect | null) => void
    onChangeEvent: (ev: any, value: IPrinterSelect | null) => void
    onChangeFeaturedHandler: (ev: any, feturedValue?: boolean) => void
    changeCategoryHandle: (_: any, value: ICategorySelect | null) => void
    changeHandleSwitch: (name: string, ev: ChangeEvent<HTMLInputElement>) => void
    error: IProductError | undefined
    tab: number
    defaultCategoryId?: string
    isConfigProduct: boolean
    systemIdentifier?: SystemIdentifierEnum | undefined
    sectorPrinters: IPrinterSelect[] | undefined
    sectorPrinterValues: IPrinterSelect[]
    onChangeSectorPrinter: (_: any, value: IPrinterSelect[] | null) => void
    disabledForm: false
}

const DetailsProduct = ({ 
    changeHandleSwitch, 
    defaultCategoryId, 
    categories, 
    error, 
    sectorPrinterValues,
    changeCategoryHandle,
    tab,
    values,
    stores,
    printers,
    events,
    onChangeStore,
    changeHandle,
    onChangePrinter,
    onChangeEvent,
    isConfigProduct,
    systemIdentifier,
    sectorPrinters,
    onChangeSectorPrinter,
    disabledForm
    }: IDetailsProduct) => {
        

    return (
        <>
            <div className={styles.grid} >
                {values.shared &&
                    <ContainerLabel >
                        <Autocomplete
                            fullWidth
                            renderInput={(params) => 
                            <InputLabel 
                                error={error?.sharedLocals && error?.sharedLocals[tab] && !!error?.sharedLocals[tab].category} 
                                helperText={error?.sharedLocals && error?.sharedLocals[tab] && error?.sharedLocals[tab].category} 
                                label={"Categoria:"} 
                                {...params} 
                                required 
                                />}
                            options={categories ?? []}
                            getOptionLabel={(option) => option.description}
                            value={values.sharedLocals && (values.sharedLocals[tab]?.category ?? null)}
                            onChange={changeCategoryHandle}
                            size='small'
                            disabled={(!!defaultCategoryId && !values.shared) || (!!defaultCategoryId && values.shared && tab === 0)}
                            placeholder="Ex.: delicioso hámburguer preparado com ingredientes especiais"
                        />
                    </ContainerLabel>
                }               

                {
                    !isConfigProduct &&
                        <ContainerLabel >
                            <Autocomplete
                                fullWidth
                                onChange={onChangeStore}
                                value={values.shared && values.sharedLocals ? values.sharedLocals[tab]?.store ?? null : values.store}
                                renderInput={(params) => <InputLabel label={"Loja"}  {...params} />}
                                options={stores ?? []}
                                getOptionLabel={(option) => option.name}
                                size='small'
                            />
                        </ContainerLabel>
                }
          
                {
                    !values.shared && !isConfigProduct &&
                        <ContainerLabel>
                            <InputLabel onChange={changeHandle} name={"type"} value={values.type} select label={"Tipo de produto"} >
                                <MenuItem value={EnumProductType.PRODUTO}  >Produto</MenuItem>
                                <MenuItem value={EnumProductType.KILO}  >Pesado</MenuItem>
                                <MenuItem value={EnumProductType.VARIAVEL}  >Variável</MenuItem>
                                <MenuItem value={EnumProductType.INGRESSO_AVULSO}  > Ingresso Avulso</MenuItem>
                                <MenuItem value={EnumProductType.INGRESSO_COM_NOME_NA_LISTA}  >Ingresso com nome na lista</MenuItem>
                                <MenuItem value={EnumProductType.DOACAO}>Doação</MenuItem>
                            </InputLabel>
                        </ContainerLabel>
                }
                {
                    systemIdentifier !== SystemIdentifierEnum.Saas && values.type !== EnumProductType.KILO &&
                        <ContainerLabel >
                            <Autocomplete
                                fullWidth
                                onChange={onChangePrinter}
                                value={values.shared && values.sharedLocals ? values.sharedLocals[tab]?.defaultPrinter ?? null : values.defaultPrinter}
                                renderInput={(params) => <InputLabel label={"Impressora padrão"}  {...params} />}
                                options={printers ?? []}
                                getOptionLabel={(option) => option.name}
                                size='small'
                            />
                        </ContainerLabel>
                }
                {
                    values.type === EnumProductType.KILO && (
                        <ContainerLabel>
                            <InputLabel  onChange={changeHandle} name="tare" value={values.tare} label="Tara (g)" />
                        </ContainerLabel>
                    )
                }

            </div>
            <div className={styles.grid} >
                <InputLabel onChange={changeHandle} name={"internalCode"} value={values.shared && values.sharedLocals ? values.sharedLocals[tab]?.internalCode ?? '' : values.internalCode} label={"Código interno"} />
                <InputLabel onChange={changeHandle} name={"integrationCode"} value={values.shared && values.sharedLocals ? values.sharedLocals[tab]?.integrationCode ?? '' : values.integrationCode} label={"Código de integração"} />
                <InputLabel onChange={changeHandle} name={"barcode"} value={values.shared && values.sharedLocals ? values.sharedLocals[tab]?.barcode ?? '' : values.barcode} label={"Código de barras"} />
            </div>
            <div className={styles.grid} >
                <InputLabel onChange={changeHandle} name={"expireTime"} value={values.shared && values.sharedLocals ? values.sharedLocals[tab]?.expireTime ?? '' : values.expireTime} label={"Tempo de expiração (min)"} />
                <InputLabel onChange={changeHandle} name={"maxQuantityPerUser"} value={values.shared && values.sharedLocals ? values.sharedLocals[tab]?.maxQuantityPerUser ?? '' : values.maxQuantityPerUser} label={"Quantidade máxima de venda por usuário"} />
                <InputLabel onChange={changeHandle} name={"genderRestriction"} value={values.shared && values.sharedLocals ? values.sharedLocals[tab]?.genderRestriction ?? EnumGender.unknow : values.genderRestriction} select label={"Restrição por sexo"} >
                    <MenuItem value={EnumGender.unknow}>Não informado</MenuItem>
                    <MenuItem value={EnumGender.male}>Masculino</MenuItem>
                    <MenuItem value={EnumGender.female}>Feminino</MenuItem>
                </InputLabel>
                
                {
                    (values.type === EnumProductType.INGRESSO_AVULSO || values.type === EnumProductType.INGRESSO_COM_NOME_NA_LISTA) && <ContainerLabel >
                        <Autocomplete
                            fullWidth
                            onChange={onChangeEvent}
                            value={values.shared && values.sharedLocals ? values.sharedLocals[tab]?.event ?? null : values.event}
                            renderInput={(params) => <InputLabel label={"Evento"}  {...params} />}
                            options={events ?? []}
                            getOptionLabel={(option) => option.name}
                            size='small'
                        />
                    </ContainerLabel>
                }

                {
                     <ContainerLabel >
                        <Autocomplete
                            fullWidth
                            renderInput={(params) => <InputLabel label="Impressão inteligente" {...params} />}
                            size='small'
                            options={sectorPrinters ?? []} 
                            getOptionLabel={(option) => option.name}  
                            multiple
                            value={
                               ( values.sharedLocals && 
                                sectorPrinters?.filter((printer) => values.sharedLocals && values.sharedLocals[tab]?.sectorPrinterIds?.includes(printer.id)) )|| []  
                                
                            }
                            onChange={onChangeSectorPrinter}
                            disabled={!!defaultCategoryId || disabledForm}
                            placeholder="Ex.: Epson TM-T20"
                            noOptionsText="Sem impressora cadastrada"
                        />
                        <IconButton
                            className={styles.redirectPrint}
                            onClick={() => window.open("/private/pdv/impressoras-inteligentes", '_blank')}
                        >
                            <OpenInNewOutlined fontSize="small" />
                        </IconButton>
                    </ContainerLabel>
                }
            </div>                   
                {
                    systemIdentifier !== SystemIdentifierEnum.Saas && values.type === EnumProductType.KILO &&
                    <div className={styles.grid} >   
                        <ContainerLabel >
                            <Autocomplete
                                fullWidth
                                onChange={onChangePrinter}
                                value={values.shared && values.sharedLocals ? values.sharedLocals[tab]?.defaultPrinter ?? null : values.defaultPrinter}
                                renderInput={(params) => <InputLabel label={"Impressora padrão"}  {...params} />}
                                options={printers ?? []}
                                getOptionLabel={(option) => option.name}
                                size='small'
                            />
                        </ContainerLabel>
                    </div>
                }
            <div className={styles.grid} >
                <div className={styles.stockControl}>
                    <SwitchLabel  label={"Gestão de estoque"} checked={values.shared && values.sharedLocals ? values.sharedLocals[tab]?.stockControl ?? false : values.stockControl} onChange={(e) => changeHandleSwitch('stockControl', e)} />
                    {((values.shared && values.sharedLocals &&values.sharedLocals[tab]?.stockControl) || (!values.shared && values.stockControl)) &&
                        <InputLabel onChange={changeHandle} name={"reservedQuantity"} value={values.shared && values.sharedLocals ? values.sharedLocals[tab]?.reservedQuantity ?? '' : values.reservedQuantity} label={"Quantidade reservada"} type='number' />
                    }
                </div>
                <SwitchLabel onChange={(e) => changeHandleSwitch('serviceFeeExemption', e)} checked={values.shared && values.sharedLocals ? values.sharedLocals[tab]?.serviceFeeExemption ?? false : values.serviceFeeExemption} label={"Isenção de taxa de serviço"} />
                <SwitchLabel onChange={(e) => changeHandleSwitch('adultHood', e)} checked={values.shared && values.sharedLocals ? values.sharedLocals[tab]?.adultHood ?? false : values.adultHood} label={"Exclusivo para maiores de 18 anos"} />
            </div>
            <div className={styles.grid} >
                <SwitchLabel onChange={(e) => changeHandleSwitch('blockPrint', e)} checked={values.shared && values.sharedLocals ? values.sharedLocals[tab]?.blockPrint ?? false : values.blockPrint} label={"Bloquear impressão"} />
                <SwitchLabel onChange={(e) => changeHandleSwitch('dontShowInKDS', e)} checked={values.shared && values.sharedLocals ? values.sharedLocals[tab]?.dontShowInKDS ?? false : values.dontShowInKDS} label={"Não exibir produto no KDS"} />
                <SwitchLabel onChange={(e) => changeHandleSwitch('featured', e)} checked={values.shared && values.sharedLocals ? values.sharedLocals[tab]?.featured ?? false : values.featured} label={"Destacar produto"} />
            </div>
        </>
    )
}

export default DetailsProduct