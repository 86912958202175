import { MenuItem, Pagination, Skeleton, TextField } from '@mui/material';
import UseDimension from 'components/dimension/UseDimension';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './ConsultDocumentsList.module.scss'
import { IConsultDocumentsColumnsConfig } from '../consultDocumentsList/interfaces/IColumnsConfig';
import { useUi } from 'contexts/userInterface/UserInterfaceContext';
import { IConsultDocumentsList, IRecordsItem } from '../../interfaces/IConsultDocumentsList';
import { IParamsConsultDocumentsList } from '../../hooks/UseConsultDocumentsList';
import ConsultDocumentsHeader from './components/consultDocumentsListContainer/ConsultDocumentsHeader';
import ConsultDocumentsItem from './components/consultDocumentsListContainer/ConsultDocumentsItem';
import { ConsultDocumentsItemMobile } from './components/consultDocumentsListMobile/ConsultDocumentsItemMobile';


export interface IConsultDocumentsListProps {
    consultDocumentsList: IConsultDocumentsList | undefined;
    isLoading: boolean | undefined;
    onChangePaginationHandle: (pagination: {
        page?: number | undefined;
        pageSize?: number | undefined;
    }) => void;
    params: IParamsConsultDocumentsList,
    setParams: React.Dispatch<React.SetStateAction<IParamsConsultDocumentsList>>;
    pagination: {
        page?: number | undefined;
        pageSize?: number | undefined;
    } | undefined;
}

export const ConsultDocumentsList: React.FC<IConsultDocumentsListProps> = ({ consultDocumentsList, isLoading, onChangePaginationHandle, params, setParams, pagination }) => {


    const { dimensions } = UseDimension();
    const { isMobile } = useUi();

    const [colunms, setColunms] = useState<IConsultDocumentsColumnsConfig[]>([
        {
            property: "document",
            title: "CPF consultado",
        },
        {
            property: "date",
            title: "Data e hora",
        },
        {
            property: "userName",
            title: "Usuário",

        },
        {
            property: "email",
            title: "Email",

        },
        {
            property: "device",
            title: "Dispositivo",

        },
        {
            property: "cost",
            title: "Custo por consulta",

        },
    ]);

    const columnsLimit = useCallback((_columns: IConsultDocumentsColumnsConfig[], width: number) => {

        if (width > 0) {
            const calcMax = (width: number) => {
                if (width > 0 && width <= 600) {
                    return 1
                } else if (width > 600 && width <= 800) {
                    return 2
                } else if (width > 800 && width <= 1024) {
                    return 3
                } else {
                    return 6
                }
            }
            const max = calcMax(width)
            let count = 0;
            const newValue = _columns.map((item, index) => {
                if (!item.hide) {
                    count++
                    if ((count) > max) {
                        return { ...item, hide: true }
                    } else {
                        return item;
                    }
                } else {
                    return { ...item, hide: false };
                }
            })
            return newValue
        } else {
            return _columns
        }
    }, [])


    useEffect(() => {
        setColunms(prev => columnsLimit(prev, dimensions.width))
    }, [columnsLimit, dimensions.width, consultDocumentsList])


    const onChangePageHandle = useCallback(
        (page: number, pageSize?: number) => {
            onChangePaginationHandle({ ...pagination, page: page - 1, pageSize });
        },
        [onChangePaginationHandle, pagination]
    );



    const List = useMemo(
        () => (
            <div className={styles.list}>
                {
                    !isMobile &&
                    <ConsultDocumentsHeader
                        columns={colunms}
                        params={params}
                        setParams={setParams}
                    />

                }
                {isLoading ? (
                    <>
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                        <Skeleton variant="rectangular" width="100%" height={60} />
                    </>
                ) : (
                    consultDocumentsList?.records?.map((item: IRecordsItem, index) => {
                        return (
                            isMobile ?
                                <ConsultDocumentsItemMobile
                                    key={index}
                                    userItemData={item}
                                    params={params}
                                    consultDocumentsList={consultDocumentsList}
                                    // reload={reload}

                                />
                                :
                                <ConsultDocumentsItem
                                    key={index}

                                    // open={open}
                                    // getPermutationAccountItem={getPermutationAccountItem}
                                    userItemData={item}
                                    // setOpenSidesheet={setOpenSidesheet}
                                    columns={colunms}
                                    params={params}
                                    consultDocumentsList={consultDocumentsList}
                                    // reload={reload}
                                />
                        );
                    })
                )}
            </div>
        ),
        [isMobile, colunms, params, setParams, isLoading, consultDocumentsList]
    );

    const countPage = useMemo(() => Math.ceil((consultDocumentsList?.totalRecords ?? 1) / (pagination?.pageSize ?? 20)), [consultDocumentsList?.totalRecords, pagination?.pageSize])



    return (
        <div id={styles.ConsultDocumentsList}>

            {
                !isLoading && !consultDocumentsList?.records?.length
                    ?
                    (<div className={styles.emptyList}>
                        <img src="/assets/img/empty-box.png" alt="" />

                        <span>Seu estabelecimento ainda <b>não possui
                            nenhuma</b> consulta.</span>
                    </div>)
                    : (
                        <>
                            {List}
                            <div className={styles.conteinerPagination}>
                                <div className={styles.boxMultiplePage}>
                                    <span>Exibir</span>
                                    <TextField variant='outlined' size='small' select onChange={(ev) => onChangePageHandle?.(1, Number(ev.target.value))}>
                                        <MenuItem className={styles.textPage} value={10} >10</MenuItem>
                                        <MenuItem className={styles.textPage} value={20} >20</MenuItem>
                                        <MenuItem className={styles.textPage} value={50} >50</MenuItem>
                                    </TextField>
                                </div>
                                <Pagination
                                    variant="outlined"
                                    className={styles.paginationNumber}
                                    shape="rounded"
                                    count={countPage}
                                    sx={{
                                        '.MuiPaginationItem-root': {
                                            borderColor: '#BDBDBD',
                                            color: '#001537',
                                        },
                                        '.Mui-selected': {
                                            fontWeight: 'bold',
                                            borderColor: '#001537',
                                        },
                                    }}
                                    onChange={(_, page) => onChangePageHandle?.(page, pagination?.pageSize)}
                                    page={((pagination?.page ? pagination?.page + 1 : 1))}
                                />
                            </div>
                        </>
                    )
            }


        </div>
    )
}
