import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './ProductForm.module.scss'
import { ICatalogSelect, ICategorySelect, IEventSelect, ILocaisSelect, IPrinterSelect, IProductFormValue, IProductSelect, IStoreSelect } from './IProducFormValuet'
import { Button, FormControlLabel, MenuItem, Radio, RadioGroup, Tab, Tabs } from '@material-ui/core';
import { SwitchLabel } from '../ui/SwitchLabel';
import { Separator } from '../ui/Separator';
import { SubTitle } from '../ui/SubTitle';
import { ContainerLabel } from '../ui/ContainerLabel';
import { EnumProductType } from 'modules/products/presentation/interfaces/IProductListData';
import { InputLabel } from '../ui/InputLabel';
import { InputMoney } from '../ui/InputMoney';
import ComplementFragment from '../../fragments/complementFragment/ComplementFragment';
import { IComplementGroupFormValue } from '../complement/complementForm/ComplementForm';
import ButtonsForm from '../ui/ButtonsForm';
import { InputImage } from '../ui/InputImage';
import ProductFormSkeleton from '../productFormSkeleton/ProductFormSkeleton';
import { ArrowBackIosRounded, KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@material-ui/icons';
import { Autocomplete } from '@mui/material';
import ObservationForm, { IObservationProductFormValue } from '../observationForm/ObservationForm';
import { v4 } from 'uuid';
import InputMasked from 'components/inputMasked/InputMasked';
import FormItemContainer from 'components/ui/inputContainer/FormItemContainer';
import { useLocal } from 'modules/local/presentation/context/LocalContext';
import { ILocalItem, ILocalList } from 'modules/local/presentation/interfaces/ILocalResume';
import DetailsProduct from '../detailsProduct/DetailsProduct';
import LocalService from 'services/api/local/LocalService';
import GetLocalsListUseCase from 'modules/local/application/useCases/GetLocalsListUseCase';
import { useAuth } from 'modules/auth/presentation/context/AuthContext';
import { Tooltip } from 'components/graphs/components/container/components/tooltip/Tooltip';
import UseQueryParams from '../../hooks/UseQueryParams';
import { IComplementProductFormValue } from '../complement/interfaces/IComplementProductFormValue';
import { IProductComplementCategoryFormValue } from '../complement/interfaces/IProductComplementCategoryFormValue';

export interface IProductFormProps {
    //propertys
    defaultCatalogId?: string,
    defaultCategoryId?: string,
    defaultValue?: IProductFormValue,
    categories?: ICategorySelect[],
    catalogs?: ICatalogSelect[],
    printers: IPrinterSelect[],
    stores: IStoreSelect[],
    events?: IEventSelect[]
    isLoading: boolean,
    onSubmit: (values: IProductFormValue) => void,
    getComplementFromProduct?: (product: IProductSelect) => Promise<IComplementGroupFormValue[]>
    onCancel?: () => void
    values: IProductFormValue
    setValues: React.Dispatch<React.SetStateAction<IProductFormValue>>
    tab: number
    setTab: React.Dispatch<React.SetStateAction<number>>
    tabCardapio: number
    setTabCardapio: React.Dispatch<React.SetStateAction<number>>
    disabledComplementGroup?: boolean,
    sectorPrinters?: IPrinterSelect[]
}

interface IProductError {
    category?: string,
    catalog?: string,
    name?: string,
    ncm?: string,
    cfop?: string,
    value?: string,
    observations?: string,
    complements?: string,
    SharedLocals?: {
        [index: number]: {
            category?: string;
        };
    };
    local?: string,
    tabs?: boolean[], // Adiciona essa linha
}

const ProductForm: FC<IProductFormProps> = ({
    categories,
    stores,
    printers,
    events,
    onCancel,
    defaultCategoryId,
    defaultValue,
    onSubmit,
    catalogs,
    defaultCatalogId,
    isLoading,
    values,
    setValues,
    tab,
    setTab,
    tabCardapio,
    setTabCardapio,
    disabledComplementGroup,
    sectorPrinters
}) => {
    const { localConfiguration, currentLocal } = useLocal();
    const { hasAccessRole } = useAuth()
    const queryParams = UseQueryParams();


    const [hideAdvancedProperty, setHideAdvancedProperty] = useState(true);
    const [error, setError] = useState<IProductError>();
    const disabledForm = false;
    const [locals, setLocals] = useState<ILocalList>()
    const [search, setSearch] = useState('')
    const localCurrent = localStorage.getItem('@localCurrent');
    const ownerData = localCurrent && JSON.parse(localCurrent);
    const _productId = queryParams.get("productId");

    const numberStep = (step: number) => {
        if (!hasAccessRole('CardapioCompartilhado')) {
            return step - 1
        } else return step
    }

    const getLocals = useCallback(
        async () => {
            const service = LocalService();
            const response = await GetLocalsListUseCase(service, { page: 1, pageSize: 500, search: search });
            setLocals(response);
        },
        [search],
    );

    useEffect(() => {
        getLocals()
    }, [getLocals, search])

    const width = window.innerWidth;

    useEffect(() => {
        if (defaultValue) {
            setValues({
                ...defaultValue,
                store: stores.find(item => item.id === defaultValue.storeId),
                complements: defaultValue.complements?.map(item => ({
                    complementCategory: { ...item.complementCategory, id: item.complementCategory.id ?? v4() },
                    complementProducts: item.complementProducts.map(item => ({ ...item, id: item.id ?? v4() }))
                }))
            });
        }
    }, [defaultValue, stores]);

    const version = useMemo(() => Math.random(), []);

    const isConfigProduct = useMemo(() =>
        defaultValue?.type === EnumProductType.RECARGA ||
        defaultValue?.type === EnumProductType.PROMOCAO ||
        defaultValue?.type === EnumProductType.ATIVACAO_CASHLESS_PRE_PAGO ||
        defaultValue?.type === EnumProductType.DESATIVACAO_CASHLESS_PRE_PAGO ||
        defaultValue?.type === EnumProductType.ATIVACAO_CASHLESS_POS_PAGO ||
        defaultValue?.type === EnumProductType.DESATIVACAO_CASHLESS_POS_PAGO ||
        defaultValue?.type === EnumProductType.ATIVACAO_POS_PAGO_COMANDA ||
        defaultValue?.type === EnumProductType.DESATIVACAO_POS_PAGO_COMANDA ||
        defaultValue?.type === EnumProductType.TAXA_DE_SERVICO ||
        defaultValue?.type === EnumProductType.AJUSTE ||
        defaultValue?.type === EnumProductType.TAXA_DE_ENTREGA
        , [defaultValue?.type]);

    useEffect(() => {
        setValues(prev => {
            let returnObject = { ...prev }
            if (defaultCategoryId && tab === 0) {
                returnObject.category = categories?.find(item => item.id === defaultCategoryId);
            }
            if (defaultCatalogId && tabCardapio === 0) {
                returnObject.catalogs = catalogs?.filter(item => item.id === defaultCatalogId);
            }
            return returnObject
        });

    }, [catalogs, categories, defaultCatalogId, defaultCategoryId]);

    useEffect(() => {
        if (values.shared && catalogs && categories) {
            const objA: ILocaisSelect = {
                productId: (_productId ?? '') || '' ,
                id: ownerData.id,
                imgUrl: ownerData.imgUrl,
                name: ownerData.name,
                cashierClosing: ownerData.cashierClosing,
                cashierOpening: ownerData.cashierOpening,
                defaultPrinter: values?.defaultPrinter,
                category: values.category ?? categories?.find(item => item.id === defaultCategoryId),
                store: values.store,
                internalCode: values.integrationCode,
                integrationCode: values.integrationCode,
                barcode: values.barcode,
                expireTime: values.expireTime,
                maxQuantityPerUser: values.maxQuantityPerUser,
                genderRestriction: values.genderRestriction,
                event: values.event,
                stockControl: values.stockControl,
                serviceFeeExemption: values.serviceFeeExemption,
                adultHood: values.adultHood,
                blockPrint: values.blockPrint,
                complements: values.complements,
                dontShowInKDS: values.dontShowInKDS,
                featured: values.featured,
                catalogs: values.catalogs ?? catalogs?.filter(item => item.id === defaultCatalogId),
                sectorPrinterIds: values.sectorPrinterIds
            }

            setValues(prev => {
                let returnObject = { ...prev }
                if (values.sharedLocals?.length === 0 || !values.sharedLocals) {
                    returnObject.sharedLocals = returnObject.sharedLocals || []
                    returnObject.sharedLocals.push(objA)
                }
                return returnObject
            });
        }

        if (!values.shared && values.sharedLocals && values.sharedLocals?.length > 0) {
            setValues(prev => ({
                ...prev,
                sharedLocals: [],
                defaultPrinter: values.sharedLocals && values.sharedLocals[0].defaultPrinter,
                category: values.sharedLocals && values.sharedLocals[0].category,
                store: values.sharedLocals && values.sharedLocals[0].store,
                internalCode: values.sharedLocals && values.sharedLocals[0].integrationCode,
                integrationCode: values.sharedLocals && values.sharedLocals[0].integrationCode,
                barcode: values.sharedLocals && values.sharedLocals[0].barcode,
                expireTime: values.sharedLocals && values.sharedLocals[0].expireTime,
                maxQuantityPerUser: values.sharedLocals && values.sharedLocals[0].maxQuantityPerUser,
                genderRestriction: values.sharedLocals && values.sharedLocals[0].genderRestriction,
                event: values.sharedLocals && values.sharedLocals[0].event,
                stockControl: values.sharedLocals && values.sharedLocals[0].stockControl,
                serviceFeeExemption: values.sharedLocals && values.sharedLocals[0].serviceFeeExemption,
                adultHood: values.sharedLocals && values.sharedLocals[0].adultHood,
                blockPrint: values.sharedLocals && values.sharedLocals[0].blockPrint,
                dontShowInKDS: values.sharedLocals && values.sharedLocals[0].dontShowInKDS,
                featured: values.sharedLocals && values.sharedLocals[0].featured,
                catalogs: values.sharedLocals && values.sharedLocals[0].catalogs,
                sectorPrinterIds: values.sharedLocals && values.sharedLocals[0].sectorPrinterIds                
            }))
        }

    }, [values.shared, catalogs, _productId, categories, defaultCatalogId, defaultCategoryId]);

    useEffect(() => {
        setTab(0)
        setTabCardapio(0)
    }, [values.shared])

    const validate = useCallback((_values: IProductFormValue) => {
        let hasError = false;
        let tabsError = Array(_values.sharedLocals?.length).fill(false);
        setError({});
    
        if (_values.sharedLocals?.length === 0 && _values.shared) {
            setError(prev => ({ ...prev, local: "Insira um local" }));
            return false;
        }
    
        if (!_values.category && !_values.shared) {
            setError(prev => ({ ...prev, category: "Categoria é obrigatória" }));
            return false;
        }
    
        if (_values.sharedLocals && _values.sharedLocals.length > 0 && _values.shared) {
            _values.sharedLocals.forEach((local, index) => {
                if (!local.category) {
                    tabsError[index] = true;
                    hasError = true;
                }
            });
    
            setError((prev) => ({
                ...prev,
                sharedLocals: _values?.sharedLocals?.map((local, index) => ({
                    category: !local.category ? "Cada local deve ter uma categoria" : undefined,
                })),
                tabs: tabsError,
            }));
    
            if (hasError) return false;
        }
    
        if (!_values.name) {
            hasError = true;
            setError(prev => ({ ...prev, name: "Nome é obrigatório" }));
        }
        if (_values.value === null || _values.value === undefined || _values.value < 0) {
            hasError = true;
            setError(prev => ({ ...prev, value: "Insira um valor válido" }));
        }
        if (!_values.ncm && values.type !== EnumProductType.TAXA_DE_SERVICO && (localConfiguration?.gerarNFCE ?? false)) {
            hasError = true;
            setError(prev => ({ ...prev, ncm: "NCM obrigatório" }));
        }
        if (!_values.cfop && values.type !== EnumProductType.TAXA_DE_SERVICO && (localConfiguration?.gerarNFCE ?? false)) {
            hasError = true;
            setError(prev => ({ ...prev, cfop: "CFOP obrigatório" }));
        }
    
        if (hasError) {
            return false;
        }
    
        setError(undefined);
        return true;
    }, [isConfigProduct, localConfiguration, values.type]);    

    const submitHandle = useCallback(() => {
        if (validate(values)) {
            onSubmit(values);
        }
    }, [onSubmit, validate, values])

    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    }, []);

    const changeHandleDetails = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        if (values.shared) {
            setValues((prev) => ({
                ...prev,
                sharedLocals: (prev.sharedLocals || []).map((item, index) => {
                    if (index === tab) {
                        return {
                            ...item,
                            [ev.target.name]: ev.target.value
                        };
                    }
                    return item;
                }),
            }));
        } else {
            setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
        }
    }, [tab, values.shared, values]);

    const changeHandleSwitch = useCallback((name, ev: ChangeEvent<HTMLInputElement>) => {
        if (values.shared) {
            setValues((prev) => ({
                ...prev,
                sharedLocals: (prev.sharedLocals || []).map((item, index) => {
                    if (index === tab) {
                        return {
                            ...item,
                            [name]: ev.target.checked
                        };
                    }
                    return item;
                }),
            }));
        } else {
            setValues((prev) => ({ ...prev, [name]: ev.target.checked }));
        }
    }, [tab, values.shared, values]);

    const changeImageHandle = useCallback((image: string) => {
        setValues((prev) => ({ ...prev, image: image }));
    }, []);

    const changeCategoryHandle = useCallback((_, value: ICategorySelect | null) => {
        if (values.shared) {
            value && setValues((prev) => ({
                ...prev,
                sharedLocals: (prev.sharedLocals || []).map((item, index) => {
                    if (index === tab) {
                        return {
                            ...item,
                            category: value,
                        };
                    }
                    return item;
                }),
                category: (tab === 0 && value) ? value : prev.category
            }));
        } else {
            value && setValues((prev) => ({ ...prev, category: value }));
        }
    }, [tab, values.shared, values]);

    const changeLocalsHandle = useCallback((_, values: ILocalItem[] | null) => {
        setValues((prev) => ({ ...prev, sharedLocals: values || [] }));
    }, []);

    const changeCatalogHandle = useCallback((_, value: ICategorySelect[] | null) => {
        if (values.shared) {
            value && setValues((prev) => ({
                ...prev,
                sharedLocals: (prev.sharedLocals || []).map((item, index) => {
                    if (index === tabCardapio) {
                        return {
                            ...item,
                            catalogs: value,
                        };
                    }
                    return item;
                }),
            }));
        }
        else {
            value && setValues((prev) => ({ ...prev, catalogs: value }));
        }

    }, [tabCardapio, values.shared, values]);

    const changeObservationHandle = useCallback((value: IObservationProductFormValue[]) => {
        value && setValues((prev) => ({ ...prev, observations: value }));
    }, []);

    const onChangeGeneralObservation = useCallback((value?: boolean) => {
        setValues((prev) => ({ ...prev, productGeneralObservation: value }));
    }, []);

    const onChangeComplement = useCallback((complements: IComplementGroupFormValue[]) => {
        console.log('complements', complements);
        setValues((prev) => {
            const newSharedLocals: ILocaisSelect[] = (prev.sharedLocals ?? []).map(localProduct => {
                let updatedLocalProduct: ILocaisSelect = {
                    ...localProduct,
                    complements: []
                };
    
                complements.forEach(newComplement => {
                    const existingComplement = localProduct.complements?.find(
                        lpComplement => lpComplement.complementProducts.every(
                            (lpProduct, index) => lpProduct.relationshipId === newComplement.complementProducts[index]?.relationshipId
                        )
                    );
    
                    if (existingComplement) {
                        console.log('EXISTE');
                        let updatedComplement: IComplementGroupFormValue = {
                            ...existingComplement,
                            complementProducts: []
                        };
    
                        newComplement.complementProducts.forEach((newComplementProduct, index) => {
                            let updatedComplementProduct: IComplementProductFormValue = {
                                ...existingComplement.complementProducts[index],
                                value: newComplementProduct.value,
                                max: newComplementProduct.max,
                                min: newComplementProduct.min,
                            };
    
                            updatedComplement.complementProducts.push(updatedComplementProduct);
                        });
    
                        updatedLocalProduct.complements?.push(updatedComplement);
                    } else {
                        console.log('Não EXISTE');
                        let newLpComplement: IComplementGroupFormValue = {
                            ...newComplement,
                            id: v4(),
                            complementProducts: []
                        };
    
                        newComplement.complementProducts.forEach(newComplementProduct => {
                            let newComplementProductObj: IComplementProductFormValue = {
                                ...newComplementProduct,
                                id: v4(),
                                value: newComplementProduct.value
                            };
    
                            newLpComplement.complementProducts.push(newComplementProductObj);
                        });
    
                        updatedLocalProduct.complements?.push(newLpComplement);
                    }
                });
    
                return updatedLocalProduct;
            });
    
            return {
                ...prev,
                complements: [...complements], 
                sharedLocals: newSharedLocals
            };
        });
    }, [setValues]);
    
    

    
    const onChangePrinter = useCallback((ev, value: IPrinterSelect | null) => {
        if (values.shared) {
            setValues((prev) => ({
                ...prev,
                sharedLocals: (prev.sharedLocals || []).map((item, index) => {
                    if (index === tab) {
                        return {
                            ...item,
                            defaultPrinter: value,
                        };
                    }
                    return item;
                }),
            }));
        } else {
            setValues((prev) => ({ ...prev, defaultPrinter: value }));
        }
    }, [tab, values.shared, values])

    const onChangeEvent = useCallback((ev, value: IPrinterSelect | null) => {
        setValues((prev) => ({ ...prev, event: value }));
    }, [])
    const onChangeStore = useCallback((ev, complements: IStoreSelect | null) => {
        if (values.shared) {
            setValues((prev) => ({
                ...prev,
                sharedLocals: (prev.sharedLocals || []).map((item, index) => {
                    if (index === tab) {
                        return {
                            ...item,
                            store: complements,
                        };
                    }
                    return item;
                }),
            }));
        } else {
            setValues((prev) => ({ ...prev, store: complements }));
        }
    }, [tab, values.shared, values])

    const changeHasSharedHandle = useCallback((_, value: string) => {
        setError(undefined)
        if (value === '1') {
            setValues((prev) => ({ ...prev, shared: false }));
        } else {
            setValues((prev) => ({ ...prev, shared: true }));
        }
    }, [])

    const onChangeFeaturedHandler = useCallback((ev, feturedValue?: boolean) => {
        setValues((prev) => ({ ...prev, featuredProduct: feturedValue }));
    }, [])


    const onChangeSectorPrinter = useCallback(
        (_: any, value: IPrinterSelect[] | null) => {
            const selectedPrinterIds = value?.map((printer) => printer.id) || [];
            
            if (values.shared) {
                setValues((prev) => ({
                    ...prev,
                    sharedLocals: (prev.sharedLocals || []).map((item, index) => {
                        if (index === tab) {
                            return {
                                ...item,
                                sectorPrinterIds: selectedPrinterIds,  // Salva os IDs das impressoras
                            };
                        }
                        return item;
                    }),
                    sectorPrinterIds: (tab === 0 && selectedPrinterIds) ? selectedPrinterIds : prev.sectorPrinterIds
                }));
            } else {
                setValues((prev) => ({ ...prev, sectorPrinterIds: selectedPrinterIds }));  // Mesma lógica aqui
            }
        },
        [tab, values.shared, values]
    );

    const sectorPrinterValues = useMemo(() => {
        return sectorPrinters?.filter(item => values.sectorPrinterIds?.includes(item.id)) ?? []
    }, [sectorPrinters, values.sectorPrinterIds])

    return (
        isLoading ? <ProductFormSkeleton /> :
            <div id={styles.ProductForm} >
                <div className={styles.container} >
                    <div onClick={onCancel} className={styles.clickBack}>
                        <ArrowBackIosRounded />
                        <p>Voltar</p>
                    </div>
                    {hasAccessRole('CardapioCompartilhado') && !isConfigProduct &&
                    <div>
                        <div className={styles.column} style={{ gap: "6px" }}>
                            <SubTitle step={isConfigProduct ? 0 : 1}>Produto <b>compartilhado?</b></SubTitle>
                            <span style={{ color: "rgba(162, 162, 162, 1)" }}>Os locais escolhidos terão o produto cadastrado compartilhado.</span>
                        </div>
                        <ContainerLabel>
                            <RadioGroup row onChange={changeHasSharedHandle} value={values.shared ? 0 : 1}>
                                <FormControlLabel value={0} control={<Radio />} label="Sim" />
                                <FormControlLabel value={1} control={<Radio />} label="Não" />
                            </RadioGroup>
                        </ContainerLabel>
                        {values.shared &&
                            <div className={styles.inputWidth} >
                                <ContainerLabel>
                                    <Autocomplete
                                        fullWidth
                                        renderInput={(params) => <InputLabel error={!!error?.local} helperText={error?.local} label={"Locais:"} {...params} required
                                            onChange={(e) => setSearch(e.target.value)}
                                            placeholder={values.sharedLocals?.length === 0 ? "Selecione os locais para compartilhar o produto" : ''} />}
                                        options={locals?.items ?? []}
                                        getOptionLabel={(option) => option.name}
                                        multiple
                                        value={values.sharedLocals}
                                        onChange={changeLocalsHandle}
                                        size='small'
                                    />
                                </ContainerLabel>
                            </div>
                        }
                    </div>
}
                    <div className={styles.column}>
                        <SubTitle step={numberStep(isConfigProduct ? 1 : 2)}>Informe os <b>detalhes:</b></SubTitle>
                        <div className={`${styles.row} ${styles.rowMobile}`} >
                            {defaultValue?.type !== EnumProductType.TAXA_DE_SERVICO && <div className={styles.imageInput} >
                                <InputImage size={180} value={values.image ?? (values.imageUrl + "?v=" + version)} onChange={changeImageHandle} />
                                <span>
                                    Formatos: JPEG e PNG<br />
                                    Resolução ideal: 400x400
                                </span>
                            </div>}
                            <div className={`${styles.row} ${styles.rowMobile}`} >
                                <div className={styles.column} >
                                    <InputLabel
                                        disabled={disabledForm || isConfigProduct}
                                        error={!!error?.name}
                                        helperText={error?.name}
                                        onChange={changeHandle}
                                        name={"name"}
                                        value={values.name}
                                        label={"Nome do produto"}
                                        required />
                                    <InputMoney
                                        disabled={disabledForm || defaultValue?.type === EnumProductType.TAXA_DE_SERVICO}
                                        error={!!error?.value}
                                        helperText={error?.value}
                                        onChange={changeHandle}
                                        name={"value"}
                                        value={values.value}
                                        label={"Valor"}
                                        required />
                                    {values.shared ? (
                                        <ContainerLabel>
                                            <InputLabel onChange={changeHandle} name={"type"} value={values.type} select label={"Tipo de produto"} required>
                                                <MenuItem value={EnumProductType.PRODUTO}  >Produto</MenuItem>
                                                <MenuItem value={EnumProductType.KILO}  >Pesado</MenuItem>
                                                <MenuItem value={EnumProductType.VARIAVEL}  >Variável</MenuItem>
                                                <MenuItem value={EnumProductType.INGRESSO_AVULSO}  > Ingresso Avulso</MenuItem>
                                                <MenuItem value={EnumProductType.INGRESSO_COM_NOME_NA_LISTA}  >Ingresso com nome na lista</MenuItem>
                                            </InputLabel>
                                        </ContainerLabel>
                                    ) : (
                                        <ContainerLabel>
                                            <Autocomplete
                                                fullWidth
                                                renderInput={(params) => <InputLabel error={!!error?.category} helperText={error?.category} label={"Categoria:"} {...params} required />}
                                                options={categories ?? []}
                                                getOptionLabel={(option) => option.description}
                                                value={values.category}
                                                onChange={changeCategoryHandle}
                                                size='small'
                                                disabled={!!defaultCategoryId || disabledForm}
                                                placeholder="Ex.: delicioso hámburguer preparado com ingredientes especiais"
                                            />
                                        </ContainerLabel>
                                    )}
                                </div>
                                {
                                    defaultValue?.type !== EnumProductType.TAXA_DE_SERVICO &&
                                    <div className={styles.column} >
                                        <div className={styles.row} >
                                            <InputLabel
                                                disabled={disabledForm}
                                                onChange={changeHandle}
                                                name="productInformation"
                                                value={values.productInformation}
                                                multiline
                                                // minRows={8.7}
                                                // minRows={currentLocal?.systemIdentifier === SystemIdentifierEnum.Saas ? 5 : 8.7}
                                                minRows={5}
                                                label="Descrição"
                                                placeholder="Ex.: delicioso hámburguer preparado com ingredientes especiais"
                                            />
                                        </div>                                        
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            defaultValue?.type === EnumProductType.TAXA_DE_SERVICO &&
                            (
                                <span className={styles.switchHelp}>
                                    <SwitchLabel disabled={disabledForm} checked={values.blockPrint} onChange={(e) => changeHandleSwitch('blockPrint', e)} label="Bloquear impressão" />
                                    <Tooltip title="Bloquear impressão na POS" text="Bloquear impressão de produto apenas no POS. Não afetando outras configurações para impressão remota" />
                                </span>
                            )
                        }
                        {defaultValue?.type !== EnumProductType.TAXA_DE_SERVICO && (
                            values.shared ? (
                                <>
                                    {values.sharedLocals && values.sharedLocals?.length > 0 &&
                                        <div>
                                            <span style={{ color: "#955CFF", fontWeight: '700' }}>Detalhes do produto</span>
                                            <Tabs
                                                value={tab}
                                                selectionFollowsFocus={!!error}
                                                onChange={(event, newValue) => setTab(newValue)}
                                                classes={{
                                                    indicator: styles.indicator,
                                                    scroller: styles.scroller,
                                                    root: styles.root
                                                }}
                                                >
                                                {values.sharedLocals?.map((item, index) => {
                                                    const hasError = error?.tabs?.[index];
                                                    return (
                                                        <Tab
                                                            key={index}
                                                            label={item.name}
                                                            classes={{
                                                                root: hasError ? styles.tabError : styles.tab,
                                                                selected: styles.selected,
                                                            }}
                                                        />
                                                    );
                                                })}
                                            </Tabs>
                                            <DetailsProduct
                                                values={values}
                                                stores={stores}
                                                printers={printers}
                                                events={events}
                                                onChangeStore={onChangeStore}
                                                changeHandle={changeHandleDetails}
                                                onChangePrinter={onChangePrinter}
                                                onChangeEvent={onChangeEvent}
                                                onChangeFeaturedHandler={onChangeFeaturedHandler}
                                                tab={tab}
                                                categories={categories}
                                                changeCategoryHandle={changeCategoryHandle}
                                                defaultCategoryId={defaultCategoryId}
                                                error={error}
                                                isConfigProduct={isConfigProduct}
                                                systemIdentifier={currentLocal?.systemIdentifier}
                                                changeHandleSwitch={changeHandleSwitch}
                                                sectorPrinters={sectorPrinters}
                                                sectorPrinterValues={sectorPrinterValues}
                                                onChangeSectorPrinter={onChangeSectorPrinter}
                                                disabledForm={disabledForm}

                                            />

                                        </div>
                                    }
                                </>
                            ) : (
                                <>
                                    {hideAdvancedProperty
                                        ? (
                                            <Button
                                                variant="text"
                                                style={{ width: 200, padding: 4 }}
                                                color="secondary"
                                                endIcon={<KeyboardArrowDownRounded />}
                                                onClick={() => setHideAdvancedProperty(false)}
                                            >
                                                Informações avançadas
                                            </Button>
                                        )
                                        :
                                        <>
                                            <DetailsProduct
                                                values={values}
                                                stores={stores}
                                                systemIdentifier={currentLocal?.systemIdentifier}
                                                printers={printers}
                                                events={events}
                                                onChangeStore={onChangeStore}
                                                changeHandle={changeHandleDetails}
                                                onChangePrinter={onChangePrinter}
                                                onChangeEvent={onChangeEvent}
                                                onChangeFeaturedHandler={onChangeFeaturedHandler}
                                                tab={tab}
                                                defaultCategoryId={defaultCatalogId}
                                                categories={categories}
                                                changeCategoryHandle={changeCategoryHandle}
                                                error={error}
                                                isConfigProduct={isConfigProduct}
                                                changeHandleSwitch={changeHandleSwitch}
                                                disabledForm={disabledForm}
                                                sectorPrinters={sectorPrinters}
                                                sectorPrinterValues={sectorPrinterValues}
                                                onChangeSectorPrinter={onChangeSectorPrinter}
                                            />
                                            <Button
                                                variant="text"
                                                style={{ width: "185px", padding: 0 }}
                                                color="secondary"
                                                endIcon={<KeyboardArrowUpRounded />}
                                                onClick={() => setHideAdvancedProperty(true)}
                                            >
                                                Informações avançadas
                                            </Button>
                                        </>
                                    }</>)
                        )
                        }
                        <Separator />
                    </div>
                    {!isConfigProduct && <div className={styles.column}>
                        <div className={styles.column} style={{ gap: "6px" }}>
                            <SubTitle step={numberStep(3)}> Em quais <b>cardápios</b> você quer usar esse produto?</SubTitle>
                            <span style={{ color: "rgba(162, 162, 162, 1)" }}>Selecione os cardápios em que você deseja que seu produto esteja disponível para os seus clientes.</span>
                        </div>
                        {values.shared && values.sharedLocals && values.sharedLocals?.length > 0 &&
                            <Tabs
                                value={tabCardapio}
                                onChange={(event, newValue) => setTabCardapio(newValue)}
                                classes={{
                                    indicator: styles.indicator,
                                    scroller: styles.scroller,
                                    root: styles.root
                                }}
                            >
                                {values.sharedLocals?.map(item => {
                                    return (
                                        <Tab
                                            label={item.name}
                                            classes={{
                                                root: styles.tab,
                                                selected: styles.selected,
                                            }} />
                                    )
                                })}
                            </Tabs>
                        }
                        <div className={`${styles.row} ${styles.inputWidth}`} >
                            <ContainerLabel>
                                <Autocomplete
                                    fullWidth
                                    renderInput={(params) => <InputLabel error={!!error?.catalog} helperText={error?.catalog} label="Cardápio" {...params} />}
                                    options={catalogs ?? []}
                                    // getOptionSelected={(opt, value) => opt.id === value.id}
                                    getOptionLabel={(option) => option.description}
                                    multiple
                                    value={values.shared && values.sharedLocals && values.sharedLocals.length > 0 ? values.sharedLocals[tabCardapio].catalogs ?? [] : values.catalogs ?? []}
                                    onChange={changeCatalogHandle}
                                    size='small'
                                    disabled={((!!defaultCatalogId || disabledForm) && !defaultValue && !values.shared) || ((!!defaultCatalogId || disabledForm) && !defaultValue && values.shared && tabCardapio === 0)}
                                />
                            </ContainerLabel>
                        </div>
                        <Separator />
                    </div>}
                    {!isConfigProduct && <div className={styles.column}>
                        <div className={styles.column} style={{ gap: "6px" }}>
                            <SubTitle step={numberStep(4)}> Conte se seu produto possui <b>complementos:</b></SubTitle>
                            <span style={{ color: "rgba(162, 162, 162, 1)" }}>Complementos são itens que podem ser selecionados junto com o produto.</span>
                        </div>
                        <ComplementFragment
                            disabled={disabledForm}
                            sharedLocals={values.sharedLocals}
                            values={[...values.complements]}
                            isShared={values.shared}
                            onChange={onChangeComplement}
                            disabledComplementGroup={disabledComplementGroup}
                        />
                        <Separator />
                    </div>}
                    <div className={styles.column}>
                        <SubTitle step={numberStep(isConfigProduct ? 2 : 5)}> Insira as <b>informações fiscais:</b></SubTitle>
                        <div className={`${styles.row} ${styles.rowMobile} ${styles.taxSettings}`} >
                            <FormItemContainer label={'NCM'} width="100%" maxWidth={500}>
                                <InputMasked mask="9999.99.99" error={!!error?.ncm} helperText={error?.ncm} disabled={disabledForm} onChange={changeHandle} name="ncm" value={values.ncm} type={width > 600 ? "number" : "tel"} />
                            </FormItemContainer>
                            <FormItemContainer label={'CEST'} width="100%" maxWidth={500}>
                                <InputMasked mask="99.999.99" disabled={disabledForm} onChange={changeHandle} name="cest" value={values.cest} type={width > 600 ? "number" : "tel"} />
                            </FormItemContainer>
                            <FormItemContainer label={'CFOP'} width="100%" maxWidth={500}>
                                <InputMasked mask="9.999" error={!!error?.cfop} helperText={error?.cfop} disabled={disabledForm} onChange={changeHandle} name="cfop" value={values.cfop} type={width > 600 ? "number" : "tel"} />
                            </FormItemContainer>
                        </div>
                        <Separator />
                    </div>
                    {!isConfigProduct && <div className={styles.column}>
                        <div className={styles.column} style={{ gap: "6px" }}>
                            <SubTitle step={numberStep(6)} > Conte se seu produto possui <b>observações:</b></SubTitle>
                            <span style={{ color: "rgba(162, 162, 162, 1)" }}>Essas são informações que podem ser marcadas junto com o produto, como opção por gelo, açúcar ou guardanapos.</span>
                        </div>
                        <ObservationForm
                            disabled={disabledForm}
                            values={values.observations ?? []}
                            onChange={changeObservationHandle}
                            onChangeGeneralObservation={onChangeGeneralObservation}
                            productGeneralObservation={values.productGeneralObservation}
                        />
                        <Separator />
                    </div>}
                    <ButtonsForm
                        onSubmit={submitHandle}
                        submitLabel='Salvar'
                        cancelLabel='Voltar'
                        onCancel={onCancel}
                        width="160px"
                    ></ButtonsForm>
                </div>
            </div >
    )
}
export default ProductForm