import React, { useCallback, useMemo, useState } from "react";
import styles from "./ConsultDocuments.module.scss";
import { moneyMaskNumber } from "services/utils/Money";
import { IConsultDocumentsColumnsConfig, ConsultDocumentsColunmsPropertyType } from "../../../consultDocumentsList/interfaces/IColumnsConfig";
import CPF from "services/utils/CPF";
import CNPJ from "services/utils/CNPJ";
import { IConsultDocumentsList, IRecordsItem } from "../../../../interfaces/IConsultDocumentsList";
import { IParamsConsultDocumentsList } from "../../../../hooks/UseConsultDocumentsList";
import Utils from "services/utils/Utils";

interface Props {
  columns: IConsultDocumentsColumnsConfig[];
  userItemData: IRecordsItem;
  consultDocumentsList: IConsultDocumentsList;
  params: IParamsConsultDocumentsList;
}

const ConsultDocumentsItem: React.FC<Props> = ({
  userItemData,
  columns,
}) => {


  const formatDocument = useCallback((document: string) => {
    if (CPF.isValid(document))
      return CPF.cpfMask(document);
    else
      return CNPJ.mask(document);
  }, []);

  const formatValues = useCallback(
    (property: ConsultDocumentsColunmsPropertyType): string => {
      if (userItemData.hasOwnProperty("id")) {
        switch (property) {
          case "document":
            return userItemData?.document
              ? "***.***.*" + formatDocument(userItemData?.document)
              : "-";
          case "cost":
            return userItemData?.cost
              ? moneyMaskNumber(userItemData?.cost)
              : "-";
          case "date":
            return userItemData?.date
              ? Utils.toDateAndTime(userItemData?.date)
              : "-";

          default:
            return String(userItemData?.[property]) ?? "-";
        }
      }
      return "-";
    },
    [formatDocument, userItemData]
  );

  const List = useMemo(
    () =>
      columns.map((colunm, index) => {
        return (
          !colunm.hide && (
            <div
              key={index}
              className={styles.row}
              style={colunm.style ?? { flex: 1 }}
            >
              <span title={formatValues(colunm.property)}>{formatValues(colunm.property)}</span>
            </div>
          )
        );
      }),
    [columns, formatValues]
  );

  return (
    <>
      <div id={styles.ConsultDocumentsItem}>
        <div className={styles.container}>
          {List}         
        </div>
      </div>    
    </>
  );
};

export default ConsultDocumentsItem;
