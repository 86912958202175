
import React, { ForwardedRef, PropsWithoutRef, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import styles from './ProductList.module.scss'
import { IProductItem } from './IProductItem'
import { moneyMask } from 'services/utils/Money'
import { AlertModal } from '../../alertModal/AlertModal'
import { Checkbox, Icon, IconButton, Switch, Tooltip } from '@mui/material'
import { EnumProductType } from '../../complement/interfaces/export enum EnumProductType {'
import { useParams } from 'react-router-dom'
import { useUi } from 'contexts/userInterface/UserInterfaceContext'
import { useAuth } from 'modules/auth/presentation/context/AuthContext'
import SidesheetShared from '../../sidesheetShared/SidesheetShared'
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import GetProductUseCase from 'modules/catalog/application/useCases/GetProductUseCase'
import CatalogService from 'services/api/catalog/CatalogService'
import { useLocal } from 'modules/local/presentation/context/LocalContext'
import { IProductFormValue } from '../../productForm/IProducFormValuet'
import { ICategoryItem } from '../categoryList/ICategoryItem'
import UseDimension from 'components/dimension/UseDimension'

export interface IProductListItemProps {
    //propertys
    productItem: IProductItem,
    checked?: boolean;
    hideInvoiceData?: boolean
    hidePrinter?: boolean
    showDeleteProduct?: boolean
    onCheckProduct?: (product: IProductItem, value: boolean) => void
    onClickProduct?: (product: IProductItem) => void
    onClickEditProduct?: (product: IProductItem, catalogId: string) => void
    onClickRemoveProduct?: (product: IProductItem) => void
    onClickDeleteProduct?: (product: IProductItem, shared: boolean) => void
    onChangeEnabledProduct?: (product: IProductItem, value: boolean, shared: boolean) => Promise<boolean | void>
    onChangeFeaturedProduct?: (product: IProductItem, value: boolean, shared?: boolean) => void
    onClickCopyProduct?: (product: IProductItem, shared?: boolean) => void
    onClickProductAccess?: (product: IProductItem, shared?: boolean) => void
    onChangeHide?: (product: IProductItem, value: boolean, shared?: boolean) => void
    currentCatalogId?: string
    dragHandleProps?: DraggableProvidedDragHandleProps | undefined;
    isDragInDropDisabled?: boolean    
    reload: (categoryItem: ICategoryItem) => Promise<void>
}
export interface ProductItemRef {
    checkProduct: (value: boolean) => void
}
const ProductListItem = ({
    productItem,
    onClickProduct,
    onClickEditProduct,
    onClickRemoveProduct,
    onChangeEnabledProduct,
    onChangeFeaturedProduct,
    onClickDeleteProduct,
    onCheckProduct,
    onClickCopyProduct,
    checked,
    hideInvoiceData,
    hidePrinter,
    showDeleteProduct,
    onClickProductAccess,
    onChangeHide,
    currentCatalogId,
    dragHandleProps,
    isDragInDropDisabled,
    reload
}: PropsWithoutRef<IProductListItemProps>, ref: ForwardedRef<ProductItemRef>) => {

    const [isFeatured, setFeatured] = useState(productItem.featured);
    const [isFeaturedShared, setFeaturedShared] = useState(productItem.featured);
    const [isEnabled, setIsEnabled] = useState(productItem.enabled);
    const [isEnabledShared, setIsEnabledShared] = useState(productItem.enabled);
    const [isChecked, setIsChecked] = useState(checked ?? false);
    const [modalRemove, setModalRemove] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [isHiden, setIsHiden] = useState<boolean>();
    const [isHidenShared, setIsHidenShared] = useState<boolean>();
    const [product, setProduct] = useState<IProductFormValue | undefined>()
    const [openSidesheet, setOpenSidesheet] = useState<null | string>(null)
    const { toast } = useUi();
    const { hasAccessRole } = useAuth();
    const catalogService = CatalogService();
    const { currentLocal } = useLocal();

    const { isMobile } = UseDimension();

    const { catalogId } = useParams<{ catalogId: string }>();

    useEffect(() => {
        if (checked !== undefined) {
            setIsChecked(checked)
        }
    }, [checked])

    const getProduct = useCallback(async () => {
        if (currentLocal) {
            var response = await GetProductUseCase(catalogService, currentLocal.id, productItem.id);
            setProduct(response);
        }
    }, [currentLocal])

    const onClickProductHandle = useCallback(() => {
        onClickProduct?.(productItem)
    }, [onClickProduct, productItem])

    const onClickEditProductHandle = useCallback(() => {
        onClickEditProduct?.(productItem, catalogId)
    }, [catalogId, onClickEditProduct, productItem])

    const onClickCopyProductHandle = useCallback((shared) => {
        onClickCopyProduct?.(productItem, shared)
    }, [onClickCopyProduct, productItem])

    const onClickRemoveProductHandle = useCallback(() => {
        onClickRemoveProduct?.(productItem)
    }, [onClickRemoveProduct, productItem])

    const onOpenRemoveProductHandle = useCallback(() => {
        setModalRemove(true)
    }, [])

    const onOpenDeleteProductHandle = useCallback(() => {       
        if (productItem.shared) {
            getProduct()
            setOpenSidesheet('Excluir')
        } else {
            onClickDeleteProduct?.(productItem, false);
        }
    }, [getProduct, onClickDeleteProduct, productItem])

    const onOpenDeleteProductHandleShared = useCallback((shared) => {  
        onClickDeleteProduct?.(productItem, shared)
    }, [onClickDeleteProduct, productItem])
 

    const onClickProductAccessHandle = useCallback((shared) => {
        onClickProductAccess?.(productItem, shared);        
    }, [onClickProductAccess, productItem])

    const onChangeEnabledProductHandle = useCallback((_, value: boolean) => {
        if (productItem.shared) {
            getProduct()
            setOpenSidesheet('Ativar/Desativar')
            setIsEnabledShared(!isEnabledShared)
        } else {
            onChangeEnabledProduct?.(productItem, value, false);
            setIsEnabled(value);
        }

    }, [getProduct, isEnabledShared, onChangeEnabledProduct, productItem]);
    

    const onChangeEnabledProductHandleShared = useCallback(async (shared: boolean) => {
        if (onChangeEnabledProduct) {
            try {            
                await onChangeEnabledProduct(productItem, isEnabledShared ?? false, shared);
            } finally {            
                setIsEnabled?.(isEnabledShared);
            }
        }
    }, [onChangeEnabledProduct, productItem, isEnabledShared]);
    
 

    const onClickDeleteProductHandle = useCallback(() => {
        onClickDeleteProduct?.(productItem, false)
    }, [onClickDeleteProduct, productItem])

    const onOpenModalDelete = useCallback(() => {
        setModalDelete(true)
    }, [])


    const onChangeHideHandle = useCallback((_, value: boolean, isHidenShared?: boolean) => {     
        if (productItem.shared) {
            getProduct()
            setOpenSidesheet('Habilitar/desabilitar')
    
            setIsHidenShared(!isHidenShared)
        } else {
            setIsHiden(!value);
            onChangeHide?.(productItem, value);
        }
    }, [getProduct, onChangeHide, productItem])    


    const onChangeHideHandleShared = useCallback((shared) => {
       

            onChangeHide?.(productItem, isHidenShared ?? false, shared);
            setIsHiden(isHidenShared)
       
    }, [onChangeHide, productItem, isHidenShared])

    const onChangeFeaturedProductHandle = useCallback((_isFeatured) => {
        if (productItem.shared) {
            getProduct()
            setOpenSidesheet('Favoritar')
            setFeaturedShared(_isFeatured)
        } else {
            onChangeFeaturedProduct?.(productItem, _isFeatured)
            setFeatured?.(_isFeatured)
        }
    }, [getProduct, onChangeFeaturedProduct, productItem])

    const onChangeFeaturedProductHandleShared = useCallback((shared) => {
      

            onChangeFeaturedProduct?.(productItem, isFeaturedShared ?? false, shared)
            setFeatured?.(isFeaturedShared)
    
    }, [onChangeFeaturedProduct, productItem, isFeaturedShared])

    const onCheckedProductHandel = useCallback(
        (checked: boolean) => {
            setIsChecked(checked);
            onCheckProduct?.(productItem, checked);
        },
        [onCheckProduct, productItem],
    )

    useEffect(() => {
        setIsChecked(!!productItem.checked)
    }, [productItem.checked])

    useImperativeHandle(
        ref,
        () => ({
            checkProduct: onCheckedProductHandel
        }),
        [onCheckedProductHandel],
    )

    const imageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const _isHiden = productItem.catalogs?.find(item => item.id === currentCatalogId)?.isHiddenProduct;
        setIsHiden(_isHiden);
        setIsHidenShared(_isHiden)
    }, [currentCatalogId, productItem.catalogs])

    const canDuplicateProduct = useMemo(() =>
        productItem.type !== EnumProductType.ATIVACAO_CASHLESS_PRE_PAGO &&
        productItem.type !== EnumProductType.DESATIVACAO_CASHLESS_PRE_PAGO &&
        productItem.type !== EnumProductType.ATIVACAO_CASHLESS_POS_PAGO &&
        productItem.type !== EnumProductType.DESATIVACAO_CASHLESS_POS_PAGO &&
        productItem.type !== EnumProductType.ATIVACAO_POS_PAGO_COMANDA &&
        productItem.type !== EnumProductType.DESATIVACAO_POS_PAGO_COMANDA &&
        productItem.type !== EnumProductType.TAXA_DE_SERVICO &&
        productItem.type !== EnumProductType.AJUSTE
        , [productItem.type])

    const canRemoveProduct = useMemo(() =>
        (productItem.type === EnumProductType.PRODUTO ||
            productItem.type === EnumProductType.VARIAVEL ||
            productItem.type === EnumProductType.KILO ||
            productItem.type === EnumProductType.INGRESSO_COM_NOME_NA_LISTA ||
            productItem.type === EnumProductType.DOACAO ||
            productItem.type === EnumProductType.INGRESSO_AVULSO) &&
            
        productItem.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')?.toLocaleLowerCase() !== 'valor variavel',
        [productItem.name, productItem.type]
    )
    const copyText = () => {
        navigator.clipboard.writeText(productItem.id);
        toast('Texto copiado: ' + productItem.id, "success");
    };

    return (
        <div ref={imageRef} id={styles.ProductListItem} onClick={onClickProductHandle}>
            <div className={styles.checkButton} >
                {!isDragInDropDisabled && <div className={styles.dragIcon} {...dragHandleProps} >
                    <Icon className={styles.titleIcon} >drag_indicator</Icon>
                </div>}
                {onCheckProduct && <Checkbox
                    style={{ height: 40 }}
                    checked={isChecked}
                    onChange={(_, _checked) => {
                        onCheckedProductHandel?.(_checked);
                    }}
                />}
                <img
                    onError={({ currentTarget }) => {
                        currentTarget.src = "/assets/icon/placeholder.svg";
                    }}
                    alt='Imagem produto'
                    src={productItem.image && productItem.image !== "" ? 
                        productItem.image : 
                            (productItem.thumbnailImageUrl && productItem.thumbnailImageUrl !== "" ? 
                            productItem.thumbnailImageUrl :
                                productItem.imageUrl && productItem.imageUrl !== "" ? productItem.imageUrl : "/assets/icon/placeholder.svg") + "?v=" + Math.random()}
                    className={styles.image}
                />
                {isMobile && (
                    <>
                        <div className={styles.descriptions} style={{ marginBottom: "auto" }}>
                            <div className={styles.name} >
                                {productItem.name}
                            </div>
                            {(hasAccessRole('Admin') || hasAccessRole('SuporteMeep')) && productItem.id &&
                                <div className={styles.id}>
                                    {productItem.id}
                                    <Icon onClick={() => copyText()}>content_copy</Icon>
                                </div>
                            }

                            <div className={styles.description} >
                                {productItem.description}
                            </div>
                        </div>
                        <div className={styles.value} style={{ marginBottom: "auto" }}>
                            {moneyMask(productItem.value.toFixed(2))}
                        </div>
                    </>
                )}
            </div>
            <>
                {!isMobile && (
                    <>
                        <div className={styles.descriptions} >
                            <div className={styles.name} >
                                {productItem.name}
                            </div>
                            {(hasAccessRole('Admin') || hasAccessRole('SuporteMeep')) && productItem.id &&
                                <div className={styles.id}>
                                    {productItem.id}
                                    <Icon onClick={() => copyText()}>content_copy</Icon>
                                </div>
                            }

                            <div className={styles.description} >
                                {productItem.description}
                            </div>
                        </div>
                        <div className={styles.value} >
                            {moneyMask(productItem.value.toFixed(2))}
                        </div>
                    </>
                )}
                <div className={styles.fiscalAndPrinter}>
                    {!hideInvoiceData && <div className={styles.fiscal}>
                        {<div className={styles.item}>
                            <label >NCM: </label>
                            <div>{!!productItem.ncm ? productItem.ncm : '--'}</div>
                        </div>}
                        {<div className={styles.item}>
                            <label >CEST: </label>
                            <div>{!!productItem.cest ? productItem.cest : '--'}</div>
                        </div>}
                        {<div className={styles.item}>
                            <label >CFOP: </label>
                            <div>{!!productItem.cfop ? productItem.cfop : '--'}</div>
                        </div>}
                    </div>}
                    {!hidePrinter && <div className={styles.printer}>
                        {<>
                            <Icon>printer</Icon>
                            {productItem?.defaultPrinter?.name ? productItem?.defaultPrinter?.name : '--'}</>
                        }
                    </div>}
                </div>

                <div className={styles.action} >
                    {
                        canDuplicateProduct &&
                        onChangeFeaturedProduct &&
                        <div className={styles.featured}>
                            <Tooltip title={'Destacar produto'}>
                                <IconButton onClick={() => onChangeFeaturedProductHandle(!isFeatured)} >
                                    <Icon className={styles.icon}>{isFeatured ? "grade" : "star_outline"}</Icon>
                                </IconButton>
                            </Tooltip>
                        </div>
                    }
                    {productItem.shared &&
                        <Tooltip title={'Produto compartilhado com outros locais'}>
                            <div className={styles.featured} >
                                <IconButton >
                                    <Icon>content_paste_go_icon</Icon>
                                </IconButton>
                            </div>
                        </Tooltip>
                    }
                    {
                        canDuplicateProduct &&
                        onChangeEnabledProduct &&
                        productItem.shared &&
                        (!currentCatalogId || currentCatalogId === 'undefined') &&
                        <div className={styles.featured}>
                            <Switch defaultChecked={isEnabled} checked={productItem.enabled} color='primary' onChange={onChangeEnabledProductHandle}></Switch>
                        </div>
                    }
                    {
                        canDuplicateProduct &&
                        onChangeEnabledProduct &&
                        !productItem.shared &&
                        (!currentCatalogId || currentCatalogId === 'undefined') &&
                        <div className={styles.featured}>
                            <Switch defaultChecked={isEnabled}  color='primary' onChange={onChangeEnabledProductHandle}></Switch>
                        </div>
                    }
                    {
                        canDuplicateProduct &&
                        onChangeHide &&
                        currentCatalogId &&
                        isHiden !== undefined &&
                        <Tooltip title={'Visibilidade do produto'}>
                            <div className={styles.featured} >
                                <IconButton onClick={() => onChangeHideHandle(null, isHiden, isHidenShared)}>
                                    {isHiden ? <Icon>visibility_off</Icon> : <Icon>visibility</Icon>}
                                </IconButton>
                            </div>
                        </Tooltip>
                    }

                    {onClickEditProduct &&
                        <Tooltip title={'Editar produto'}>
                            <IconButton onClick={onClickEditProductHandle}>
                                <Icon>edit</Icon>
                            </IconButton>
                        </Tooltip>}
                    {onClickCopyProduct &&
                     canDuplicateProduct &&
                        <Tooltip title={'Copiar produto'}>
                            <IconButton onClick={() => productItem.shared ? (setOpenSidesheet('Duplicar'), getProduct()) : onClickCopyProduct(productItem)}>
                                <Icon>content_copy</Icon>
                            </IconButton>
                        </Tooltip>}
                    {
                        onClickRemoveProduct &&
                        canRemoveProduct &&
                        productItem.catalogs?.length &&
                        productItem.catalogs?.length > 1 ?
                        (
                            <Tooltip title={'Remover produto da lista'}>
                                <IconButton onClick={() => productItem.shared ? (setOpenSidesheet('Remover'), getProduct()) : setModalRemove(true)}>
                                    <Icon>delete</Icon>
                                </IconButton>
                            </Tooltip>
                        )
                        :
                        (
                            onClickDeleteProduct &&
                            canRemoveProduct &&
                                <Tooltip title={'Apagar produto '}>
                                    <IconButton onClick={() => productItem.shared ? onOpenDeleteProductHandle() : setModalDelete(true)}>
                                        {showDeleteProduct ? <Icon>delete</Icon> : <Icon>delete</Icon>}
                                    </IconButton>
                                </Tooltip>
                        )
                    }
                    {
                        (onClickProductAccess && 
                            <IconButton onClick={() => productItem.shared ? (setOpenSidesheet('Bloquear'), getProduct()) : onClickProductAccessHandle(false)}>
                                {<Icon>lock</Icon>}
                            </IconButton>
                        )
                    }
                </div>
            </>
            <SidesheetShared
                onClickProductAccessHandle={onClickProductAccessHandle}
                onClickCopyProductHandle={onClickCopyProductHandle}
                onOpenDeleteProductHandle={onOpenDeleteProductHandleShared}
                openSidesheet={openSidesheet}
                setOpenSidesheet={setOpenSidesheet}
                locals={product?.sharedLocals}
                onChangeFeatured={onChangeFeaturedProductHandleShared}
                onChangeEnabled={onChangeEnabledProductHandleShared}
                onChangeHide={onChangeHideHandleShared}
            />
            <AlertModal
                open={modalRemove}
                title={<h2>Remover <b>produto do cardápio</b></h2>}
                text={<div>Deseja remover <b>{productItem.name}</b></div>}
                subText={<span><b>Não se preocupe:</b> essa ação irá remover seu produto apenas deste cardápio e ele permanecerá disponível nos demais. Caso queira excluir o produto definitivamente, acesse a guia <b style={{ color: "rgba(50, 0, 142, 1)" }}>Todos os Produtos.</b></span>}
                onClose={() => setModalRemove(false)}
                confirmLabel={'Remover'}
                cancelLabel={'Voltar'}
                onConfirm={onClickRemoveProductHandle}
                alertImg="/assets/icon/alert-negative.svg"
            />
            {
                productItem.isProductComposition ? 
                <AlertModal
                    open={modalDelete}
                    title={<h2>Excluir <b>produto</b></h2>}
                    text={<div>Este produto está como adicional em outros itens do seu cardápio. Deseja realmente excluir?</div>}
                    onClose={() => setModalDelete(false)}
                    confirmLabel={'Excluir'}
                    cancelLabel={'Voltar'}
                    onConfirm={onClickDeleteProductHandle}
                    alertImg="/assets/icon/alert-negative.svg"
                />
                :
                <AlertModal
                    open={modalDelete}
                    title={<h2>Excluir <b>produto</b></h2>}
                    text={<div>Deseja realmente excluir <b>{productItem.name}</b>?</div>}
                    subText={<span><b>Atenção:</b> ao confirmar a exclusão permanente do produto, ele não estará mais disponível em nenhum cardápio.</span>}
                    onClose={() => setModalDelete(false)}
                    confirmLabel={'Excluir'}
                    cancelLabel={'Voltar'}
                    onConfirm={onClickDeleteProductHandle}
                    alertImg="/assets/icon/alert-trash.svg"
                />
            }
        </div>
    )
}
export default forwardRef<ProductItemRef, IProductListItemProps>(ProductListItem)