import Sidesheet from "components/sidesheet/Sidesheet";
import ContentBox from "../../components/_configCampaign/contentBox/ContentBox";
import { IntegrationTypeEnum } from "../../interfaces/IntegrationTypeEnum";
import { UseConfigReceitaFederal } from "./UseConfigReceitaFederal";
import { useCallback, useEffect, useMemo, useState } from "react";
import ConfigContentReceitaFederal from "../../components/_configCampaign/configContentReceitaFederal/ConfigContentReceitaFederal";
import ConfigContentConnectedReceitaFederal from "../../components/_configCampaign/configContentReceitaFederal/ConfigContentConnectedReceitaFederal";
import SidesheetFeedback from "components/sidesheet/sidesheetFeedback/SidesheetFeedback";
import UseDeleteSidesheet from "../../hook/UseDeleteSidesheet";
import { useUi } from "contexts/userInterface/UserInterfaceContext";
import styles from "./ConfigReceitaFederal.module.scss";
import { useHistory } from "react-router-dom";
import { fabClasses } from "@mui/material";

export interface IConfigReceitaFederalProps {
  open: IntegrationTypeEnum | null;
  setOpen: React.Dispatch<React.SetStateAction<IntegrationTypeEnum | null>>;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const ConfigReceitaFederal = ({ open, setOpen, step, setStep }: IConfigReceitaFederalProps) => {
  const [success, setSuccess] = useState<boolean>(true);
  const [hasCalled, setHasCalled] = useState<boolean>(false);
  const [deleteStep, setDeleteStep] = useState<number>(1);
  const [isConnected, setIsConnected] = useState<boolean>(false);

  const { deleteType, setDeleteType } = UseDeleteSidesheet();
  const { toast } = useUi();
  const { push } = useHistory();

  const { isLoading, postReceitaFederal, values, deleteContratoReceitaFederal } = UseConfigReceitaFederal();

  const handleCallStatus = useCallback(async () => {
    if(values?.acceptedAt !== null || values?.acceptedAt !== undefined || values?.acceptedAt !== '') {
      setIsConnected(values?.isActive ?? false);
    }
  }, [values]);

  const goReportConsultDocuments = () => {
    push(`/private/consult-documents`)
  }

  useEffect(() => {
    handleCallStatus();
  }, [handleCallStatus]);

  const handleContinueButton = useCallback(async () => {
    const res = await postReceitaFederal();
    if (res === "ok") { return setStep(step + 1) } else {
      setSuccess(false)
    }
  }, [postReceitaFederal, setStep, step]);

  const handleStatus = useMemo(() => (  values?.isActive ? "enabled" : "disabled"), [values?.isActive]);

  const handleDeleteConfig = useCallback(async () => {
    const res = await deleteContratoReceitaFederal();
    if (res === "ok") { return setDeleteStep(deleteStep + 1) } else {
      setSuccess(false)
    }
  }, [deleteContratoReceitaFederal, deleteStep]);

  const onCloseDeleteModal = () => {
    setDeleteType(null)
    setDeleteStep(1)
  }

  return (
    <>
      <ContentBox
        status={handleStatus}
        type={IntegrationTypeEnum.RECEITA_FEDERAL}
        text=" Ferramenta de preenchimento automático de dados através do CPF integrado à receita federal à R$ 0,10 por consulta."
        setOpen={setOpen}
        directPage
        loading={isLoading}
        getMoreRef="/private/receita-federal"
      />
      {open && open === IntegrationTypeEnum.RECEITA_FEDERAL && !isConnected && (
        <Sidesheet
          open={open}
          onClose={() => setOpen(null)}
          title={
            <h2>
              Integração - <b>{open}</b>
            </h2>
          }
          content={
            <div className={styles.receitaFederalIntegrationWrapper}>
              <ConfigContentReceitaFederal values={values} />
            </div>
          }
          cancelButton
          continueButton="Concluir"
          handleContinueButton={handleContinueButton}
          isLoading={isLoading}
          currentStep={step}
          totalSteps={2}
          feedback={
            <SidesheetFeedback
              text={success ? "Integração realizada com sucesso!" : "Erro ao realizar integração, tente novamente!"}
              success={success}
            />
          }
        />
      )}

      {open && open === IntegrationTypeEnum.RECEITA_FEDERAL && isConnected && (
        <Sidesheet
          open={open}
          onClose={() => setOpen(null)}
          title={
            <h2>
              Consultas - <b>{open}</b>
            </h2>
          }
          content={
            <div className={styles.receitaFederalIntegrationWrapper}>
              <ConfigContentConnectedReceitaFederal values={values} goReportConsultDocuments={goReportConsultDocuments} />
            </div>
          }
          continueButton="Fechar"
          handleContinueButton={() => setOpen(null)}
          isLoading={isLoading}
          currentStep={step}
          totalSteps={1}
        />
      )}
      {deleteType && deleteType === IntegrationTypeEnum.RECEITA_FEDERAL && (
        <Sidesheet
          open={deleteType}
          onClose={onCloseDeleteModal}
          title={<h2>Remover integração</h2>}
          content={
            <SidesheetFeedback
              text="Deseja remover a integração?"
              helperText="Ao remover a integração os dados dos clientes consultados ainda permanecerão na listagem de clientes."
            />
          }
          cancelButton
          continueButton="Remover"
          handleContinueButton={handleDeleteConfig}
          isLoading={isLoading}
          currentStep={deleteStep}
          totalSteps={2}
          feedback={
            <SidesheetFeedback
              text="Integração removida com sucesso!"
              success
            />
          }
        />
      )}
    </>
  );
};

export default ConfigReceitaFederal;
