import { useCallback, useEffect, useState } from "react";
import UseConsultDocumentsList, { IParamsConsultDocumentsList } from "../hooks/UseConsultDocumentsList";
import { IConsultDocumentsfilter } from "../components/consultDocumentsFilter/interfaces/ConsultDocumentsFilter";

export const UseConsultDocumentsPage = () => {

    const { getConsultDocumentsList, consultDocumentsList, isLoading } = UseConsultDocumentsList();


    const [params, setParams] = useState<IParamsConsultDocumentsList>({
        sort: { orientation: 'asc', type: 'startDate' },
        pagination: { page: 0, pageSize: 20 }
    });

    const onChangePaginationHandle = useCallback((pagination: { page?: number; pageSize?: number }) => {
        setParams((prev) => ({ ...prev, pagination }));
    }, [setParams]);

    const onChangeFilterHandle = useCallback((filter: IConsultDocumentsfilter) => {
        setParams((prev) => ({ ...prev, filter, pagination: { page: 0, pageSize: prev?.pagination?.pageSize } }));
    }, [setParams]);

    useEffect(() => {
        getConsultDocumentsList({ ...params });
    }, [getConsultDocumentsList, params]);


    return (
        {
            consultDocumentsList,
            isLoading,
            onChangePaginationHandle,
            params,
            setParams,
            onChangeFilterHandle,
            getConsultDocumentsList
        }
    )
}
